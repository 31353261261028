import Head from 'next/head';
import { ImageFragment } from '@codegen/cmsUtils';
import { useConstants } from '@web/context/ConstantContext';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';

export enum OpenGraphType {
  WEBSITE = 'website',
}

export interface Hreflang {
  locale: string;
  value: string;
}

const SEO = ({
  canonicalUrl,
  description,
  hreflangs,
  isIndexed,
  openGraphImage,
  openGraphType = OpenGraphType.WEBSITE,
  title,
}: {
  canonicalUrl?: Maybe<string>;
  description?: Maybe<string>;
  hreflangs: Hreflang[];
  isIndexed: boolean;
  openGraphImage?: Maybe<ImageFragment>;
  openGraphType?: OpenGraphType;
  title: Maybe<string>;
}) => {
  const { locale: currentLocale } = useConstants();
  const { platformName, platformUrl } = usePartnerInfo();
  const canonicalWithDomain = `${platformUrl}${canonicalUrl}`;

  return (
    <Head>
      {title && <title>{title}</title>}
      {canonicalUrl && (
        <>
          <link href={canonicalWithDomain} rel="canonical" />
          <meta content={canonicalWithDomain} property="og:url" />
        </>
      )}

      {description && (
        <>
          <meta content={description} name="description" />
          <meta content={description} property="og:description" />
        </>
      )}
      {!isIndexed && <meta content="noindex" name="robots" />}
      {isIndexed &&
        hreflangs.map(({ locale, value }) => {
          return (
            <link
              href={`${platformUrl}${value}`}
              hrefLang={locale}
              key={value}
              rel="alternate"
            />
          );
        })}
      {title && <meta content={title} property="og:title" />}
      <meta content={openGraphType} property="og:type" />
      {platformName?.value && (
        <meta content={platformName.value} property="og:site_name" />
      )}
      <meta content={currentLocale} property="og:locale" />
      {isIndexed &&
        hreflangs.map(
          ({ locale }) =>
            locale !== currentLocale && (
              <meta
                content={locale}
                key={locale}
                property="og:locale:alternate"
              />
            ),
        )}
      {openGraphImage && (
        <meta content={openGraphImage.url} property="og:image" />
      )}
    </Head>
  );
};

export default SEO;
