import { useGetPartnerFrontPageContentQuery } from '@codegen/cmsUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useExperiments } from '@web/context/ExperimentContext';
import { constructFrontPageCustomConfig } from '../utils/frontPageUtils';

const useGetFrontPageData = () => {
  const { locale, partner } = useConstants();
  const { experimentData } = useExperiments();

  const { data } = useGetPartnerFrontPageContentQuery({
    partner,
    locale,
  });

  const frontPageConfigExperiment = experimentData?.frontPageConfig;

  const config = data?.partner?.frontPageConfig;

  const coverConfig =
    frontPageConfigExperiment?.frontPageCoverConfig ||
    config?.frontPageCoverConfig;

  const content =
    frontPageConfigExperiment?.frontPageContent || config?.frontPageContent;

  const cover = constructFrontPageCustomConfig({
    config: coverConfig,
  });

  return {
    cover,
    content,
  };
};

export default useGetFrontPageData;
