import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageFragment, VendorLinkFragment } from '@codegen/cmsUtils';
import Image from '@ui-v2/core/Image/Image';
import { legacySpacings } from '@ui-v2/theme/layout';
import { createTypography, mqMin } from '@ui-v2/utils/styleUtils';
import { getImageSizes } from '../../utils/getImageSizes';

export interface Props {
  backgroundImage: Maybe<ImageFragment>;
  externalLink: Maybe<VendorLinkFragment>;
  text: Maybe<string>;
  title: Maybe<string>;
}

const Title = styled.div(({ theme: { colours, typography, zIndices } }) => [
  createTypography(typography.heading03),
  css`
    z-index: ${zIndices[2]};
    padding: ${legacySpacings.Medium}px;
    padding-bottom: 0;
    color: ${colours.text.on.interactive.primary.default};
    font-size: 28px;

    ${mqMin[1280]} {
      max-width: 60%;
    }
  `,
]);

const HoverText = styled.div(
  ({ theme: { colours, zIndices } }) => css`
    z-index: ${zIndices[2]};
    padding: 0 ${legacySpacings.Medium}px;
    color: ${colours.text.on.interactive.primary.default};
    font-weight: bold;
    opacity: 0;
    transition: all 0.15s ease-out;
    visibility: hidden;
  `,
);

const Label = styled.div(
  ({ theme: { colours, zIndices } }) => css`
    z-index: ${zIndices[2]};
    padding: ${legacySpacings.Medium}px;
    padding-top: 0;
    color: ${colours.text.on.interactive.primary.default};
    font-weight: bold;
  `,
);

const ImageContainer = styled.div(
  ({ theme: { colours } }) => css`
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: ${colours.brand.primary};

    > img {
      width: 100%;
      height: 100%;
      filter: brightness(50%);
      opacity: 1;
      transition: all 0.15s ease-out;
      visibility: visible;
    }
  `,
);

const LinkWrapper = styled(Link)(
  ({ theme }) => css`
    position: absolute;
    z-index: -${theme.zIndices[2]};
    width: 100%;
    height: 100%;

    ${mqMin[768]} {
      z-index: ${theme.zIndices[3]};
    }
  `,
);

const Container = styled.div(
  ({ theme: { shadows, shape, spacings, typography, zIndices } }) => [
    createTypography(typography.body01),
    css`
      position: relative;
      display: flex;
      min-height: 300px;
      flex-direction: column;
      justify-content: space-between;
      border-radius: ${shape.borderRadiusS}px;
      box-shadow: ${shadows.medium};
      gap: ${spacings['8']}px;

      &:hover {
        ${HoverText} {
          opacity: 1;
          transition: all 0.15s linear;
          visibility: visible;
        }

        ${ImageContainer} {
          > img {
            opacity: 0;
            transition: all 0.15s linear;
            visibility: hidden;
          }
        }

        ${LinkWrapper} {
          z-index: ${zIndices[3]};
          transition: all 0.15s linear;
        }
      }
    `,
  ],
);

const HoverCard = ({ backgroundImage, externalLink, text, title }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <HoverText>{text}</HoverText>
      <Label>{externalLink?.label}</Label>

      <ImageContainer>
        {backgroundImage && (
          <Image
            alt={backgroundImage.alt}
            fill
            objectFit="cover"
            quality={75}
            sizes={getImageSizes({
              Small: '100vw',
              Large: '50vw',
              Default: '320px',
            })}
            src={backgroundImage.url}
          />
        )}
      </ImageContainer>
      {externalLink && externalLink.link && (
        <LinkWrapper href={externalLink.link} target="_blank" />
      )}
    </Container>
  );
};

export default HoverCard;
