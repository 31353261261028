import { HoverCardFragment } from '@codegen/cmsUtils';
import HoverCard from '@ui/components/HoverCard/HoverCard';
import LayoutGrid from '@ui-v2/core/Grid/LayoutGrid';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useSettings } from '@web/context/SettingsContext';

interface Props {
  cards: Maybe<HoverCardFragment[]>;
}

const HoverCardWrapper = ({ cards }: Props) => {
  const { locale } = useConstants();
  const { residency } = useSettings();

  if (!cards) {
    return null;
  }

  return (
    <LayoutGrid
      gap={16}
      gridTemplateColumns={[
        'auto',
        'auto',
        'auto',
        'auto',
        `repeat(${cards.length}, 1fr)`,
      ]}
    >
      {cards.map((cardInfo) => (
        <HoverCard
          backgroundImage={cardInfo.backgroundImage}
          externalLink={getVendorLink({
            links: cardInfo.externalLink,
            locale,
            residency,
          })}
          key={cardInfo.id}
          text={cardInfo.hoverText?.value}
          title={cardInfo.title?.value}
        />
      ))}
    </LayoutGrid>
  );
};

export default HoverCardWrapper;
