import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTypography } from '@ui-v2/utils/styleUtils';
import { centerContent } from '../../styles/base';
import { resetButton } from '../../styles/cssReset';

interface Props {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const Tab = styled.button<{ isActive: boolean }>(
  ({ isActive, theme: { colours, shape } }) => [
    resetButton,
    css`
      overflow: hidden;
      min-width: 150px;
      flex: 1 1 0px;
      padding-bottom: 1px;
      border: 1px solid rgba(0 0 0 / 5%);
      margin: 0 1px;
      background: ${colours.surface.main};
      border-top-left-radius: ${shape.borderRadiusS}px;
      border-top-right-radius: ${shape.borderRadiusS}px;
      box-shadow: 1px 1px 4px 1px rgba(0 0 0 / 25%);
      cursor: pointer;

      &:hover {
        border-bottom: 2px solid ${colours.brand.primary};
      }
    `,
    isActive &&
      css`
        border-bottom: 2px solid ${colours.brand.primary};
      `,
  ],
);

// A generic tan content container
export const TabContent = styled.div(({ theme }) => [
  createTypography(theme.typography.heading04),
  centerContent,
  css`
    width: 100%;
    height: 100%;
  `,
]);

const CarouselTab = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, isActive, onClick }: Props, ref) => {
    return (
      <Tab isActive={isActive} onClick={onClick} ref={ref}>
        {children}
      </Tab>
    );
  },
);

export default CarouselTab;
