import { css, Theme } from '@emotion/react';
import Box, { BoxProps } from '@ui-v2/core/Box/Box';

const DEFAULT_PADDING = 16;

export interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: BoxProps['gap'];
  pb?: BoxProps['pb'];
  pl?: BoxProps['pl'];
  pr?: BoxProps['pr'];
  pt?: BoxProps['pb'];
  variant?: 'default' | 'stacked';
}

export const tileStyles = ({ colours, shadows, shape, spacings }: Theme) => css`
  padding: ${spacings['24']}px ${spacings['16']}px;
  border: 1px solid ${colours.border.subdued};
  border-radius: ${shape.borderRadiusM}px;
  background: ${colours.surface.main};
  box-shadow: ${shadows.medium};
`;

const Tile = ({
  children,
  className,
  gap,
  pb = DEFAULT_PADDING,
  pl = DEFAULT_PADDING,
  pr = DEFAULT_PADDING,
  pt = DEFAULT_PADDING,
  variant = 'default',
}: Props) => {
  return (
    <Box
      bg="surface.main"
      border="subdued"
      borderRadius={variant === 'default' ? 'borderRadiusM' : 'borderRadiusS'}
      boxShadow={variant === 'default' ? 'medium' : undefined}
      className={className}
      display="flex"
      flexDirection="column"
      gap={gap}
      pb={pb}
      pl={pl}
      pr={pr}
      pt={pt}
    >
      {children}
    </Box>
  );
};

export default Tile;
